
































import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import SupplierOrder from '@/views/supplier/Order.vue'

@Component({
  components: {
    SupplierOrder,
  },
})
export default class ItemTagDialog extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private value: boolean

  @Prop({ type: String, required: true })
  private token: string

  private get visible() {
    return this.value
  }

  private set visible(value) {
    this.$emit('input', value)
  }

  private update() {
    this.$emit('update')
    this.visible = false
  }
}
